import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00ff50',
        secondary: '#e1ffe3',
        accent: '#09bd66',
        info: '#09bd66',
      },
      // light: {
      //   primary: '#D3C3BC',
      //   secondary: '#BFADA5',
      //   accent: '#d8ebf1',
      //   info: '#69a1bb',
      // },
    },
  },
})
